<template>
    <base-card class="pa-4">
        <v-card-title class="pa-1 pl-3 font-weight-bold">Advance Filter</v-card-title>
        <v-row class="ma-0 pa-0">
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.table"
                    :items="tableLists" :menu-props="{ bottom: true, offsetY: true }"
                    label="Table"
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.event"
                    :items="eventLists" :menu-props="{ bottom: true, offsetY: true }"
                    label="Event"
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-menu v-model="menu" transition="scale-transition"
                    :close-on-content-click="false" offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="donateDateText"
                                        label="Donation Range"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <span>{{donateDateTooltip}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <div class="range-calendar">
                        <vc-date-picker mode="date" class="date-picker-range" v-model="donateDate" is-range :masks="{ L: 'YYYY-MM-DD' }"
                            timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD' }"/>                           
                        <v-btn class="ma-2"  dark color="primary" @click="menu=false;">
                            Ok
                        </v-btn>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <div class="skeleton-list-item w-full" v-if="searchPickLsLoading">
                    <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
                </div>
                <v-autocomplete v-if="!searchPickLsLoading" v-model="searchModel.donation_type"
                    hide-details="auto" label="" placeholder="Select Donation Type"
                    :items="donationTypeLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                    outlined
                >
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-card-actions style="cursor:pointer">
            <v-btn type="button" class="ma-2" dark color="info" @click="resetModel()">
                <v-icon left>mdi-refresh</v-icon>
                Reset
            </v-btn>
            <v-btn type="button" class="ma-2" dark color="primary" @click="searchFilter">
                <v-icon small left>mdi-account-search-outline</v-icon>
                Search
            </v-btn>
        </v-card-actions>
    </base-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['clearAdvFilter'],
    emits: ['reset', 'search'],
    data() {
        return {
            donateDate: {
                start: null,
                end: null,
            },
            tableLists: [{text: 'Donations', value: 'donations'}, {text: 'Donors', value: 'donors'}, {text: 'Transactions', value: 'transactions'}],
            eventLists: [{text: 'Created', value: 'created'}, {text: 'Deleted', value: 'deleted'}, {text: 'Restored', value: 'restored'},
                {text: 'Updated', value: 'updated'}],
            donationTypeLists: [],
            searchModel: {},
            menu: false,
        }
    },
    computed: {
        ...mapGetters(["searchPickLsLoading"]),

        donateDateText () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.start)} - ${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.end)}`;
        },
        donateDateTooltip () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.start)}  to  ${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.end)}`;
        },
    },
    watch: {
        'clearAdvFilter': function(newVal) {
            if (newVal) {
                this.resetModel('clear')
            }
        }
    },
    created() {
        this.getListData();
    },
    mounted() {
        this.donateDate = {
            start:  this.$helpers.getJsDateBeginEnd('start'),
            end: this.$helpers.getJsDateBeginEnd(),
        }
    },
    methods: {
        ...mapActions(["getSearchPicklist"]),

        getListData() {
            const reqData = [{object: "Opportunity", field: "Type"}];
            this.getSearchPicklist(reqData).then((response) => {
                 if (!_.isEmpty(response)) {
                    this.donationTypeLists = response.Type;
                }
            }).catch();
        },
        resetModel(clearAdv) {
            this.searchModel = {};
            this.donateDate ={
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
            if (!clearAdv) {
                this.$emit('reset');
            }
        },
        searchFilter() {
            this.searchModel.sd = this.$helpers.getMomentDatas('DD-MM-YYYY', this.donateDate.start);
            this.searchModel.ed = this.$helpers.getMomentDatas('DD-MM-YYYY', this.donateDate.end);
            this.$emit('search', this.searchModel);
        }
    }
}
</script>